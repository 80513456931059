<script setup lang="ts">
const props = defineProps({
	modelValue: {
		type: Object,
		required: true,
	},
	onClear: {
		type: Function,
		required: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const { t } = useI18n({
	useScope: 'local'
})

const regionStore = useRegionStore()

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const copiedValue = ref({ ...localValue.value })

const isOpenModal = ref(false)

const onConfirm = () => {
	localValue.value = copiedValue.value
}
</script>

<template>
	<USelectMenu
		v-model="copiedValue"
		:searchable="false"
		:options="regionStore.getOptions"
		:popper="{ placement: 'bottom-start' }"
		:ui="{
			wrapper: 'inherit md:relative'
		}"
		:ui-menu="{
			width: 'w-max'
		}"
		option-attribute="label"
		by="value"
		class="w-fit hidden md:block"
	>
		<template #option="{ option }">
			<span class="truncate">
				{{ option.label }}
			</span>
			<UBadge
				v-if="option.disabled"
				color="orange"
				size="xs"
			>
				{{ t('message.soon') }}
			</UBadge>
		</template>
	</USelectMenu>
	<div class="block md:hidden">
		<UButton
			color="gray"
			variant="outline"
			trailing-icon="i-heroicons-chevron-down-20-solid"
			:ui="{
				icon: {
					base: 'text-gray-400 dark:text-gray-500'
				}
			}"
			@click="isOpenModal = true"
		>
			{{ copiedValue.label }}
		</UButton>
		<SearchFilterModal
			v-model="isOpenModal"
			:title="t('title')"
			:on-confirm="onConfirm"
			:on-clear="onClear"
		>
			<UCommandPalette
				v-model="copiedValue"
				:searchable="false"
				by="value"
				nullable
				:groups="[{ key: 'default', commands: regionStore.getOptions as [] }]"
				:ui="{
					wrapper: 'divide-none'
				}"
			>
				<template #default-inactive="{ command }">
					<UBadge
						v-if="command.disabled"
						color="orange"
						size="xs"
						class="opacity-50"
					>
						{{ t('message.soon') }}
					</UBadge>
				</template>
			</UCommandPalette>
		</SearchFilterModal>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"title": "Регион",
		"message": {
			"soon": "Скоро"
		}
	}
}
</i18n>