<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const props = defineProps({
	modelValue: {
		type: Object,
		required: true,
	},
	postfix: {
		type: String,
		default: null
	},
	autofocus: {
		type: Boolean,
		default: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const { t } = useI18n({
	useScope: 'local'
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.isGreaterOrEqual('md')

const inputFocus = ref<any | null>(null)

const formHelper = useFormHelper()

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const onCheckFrom = () => {
	if (!formHelper.isNumber(localValue.value.from) || !formHelper.isNumber(localValue.value.to)) {
		return
	}
	if (localValue.value.from > localValue.value.to) {
		localValue.value.from = localValue.value.to
	}
}

const onCheckTo = () => {
	if (!formHelper.isNumber(localValue.value.from) || !formHelper.isNumber(localValue.value.to)) {
		return
	}
	if (localValue.value.to < localValue.value.from) {
		localValue.value.to = localValue.value.from
	}
}

onMounted(() => {
	if (props.autofocus) {
		setTimeout(() => {
			inputFocus.value?.input.focus()
		}, isDesktop ? 0 : 300)
	}
})
</script>

<template>
	<div
		v-bind="$attrs"
		class="flex content-stretch gap-2"
	>
		<UInput
			ref="inputFocus"
			v-model="localValue.from"
			type="number"
			:placeholder="t('placeholder.from')"
			class="w-full md:w-24"
			@blur="onCheckFrom"
		>
			<template v-if="postfix" #trailing>
				<span class="text-sm">
					{{ postfix }}
				</span>
			</template>
		</UInput>
		<UInput
			v-model="localValue.to"
			type="number"
			:placeholder="t('placeholder.to')"
			class="w-full lg:w-24"
			@blur="onCheckTo"
		>
			<template v-if="postfix" #trailing>
				<span class="text-sm">
					{{ postfix }}
				</span>
			</template>
		</UInput>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"placeholder": {
			"from": "от",
			"to": "до"
		}
	}
}
</i18n>