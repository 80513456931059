<script setup lang="ts">
import type { PropType } from 'vue'
import type { ListItem } from '~/types'

const props = defineProps({
	modelValue: {
		type: Object,
		required: true,
	},
	options: {
		type: Object as PropType<ListItem[]>,
		required: true
	},
	onClear: {
		type: Function,
		default: () => ({})
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const { t } = useI18n({
	useScope: 'local'
})

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const isOpen = ref(false)

const onConfirm = () => {

}
</script>

<template>
	<USelectMenu
		v-bind="$attrs"
		v-model="localValue.value"
		value-attribute="value"
		:options="options"
		:popper="{ placement: 'bottom-start' }"
		:ui="{
			wrapper: 'inherit md:relative'
		}"
		:ui-menu="{
			width: 'w-max'
		}"
		class="select-menu w-fit hidden md:block"
	>
		<template #option="{ option }">
			<span class="truncate">
				{{ option.label }}
			</span>
			<UBadge
				v-if="option.disabled"
				color="orange"
				size="xs"
			>
				{{ t('message.soon') }}
			</UBadge>
		</template>
	</USelectMenu>
	<div
		v-bind="$attrs"
		class="block md:hidden"
	>
		<UButton
			color="gray"
			variant="outline"
			trailing-icon="i-heroicons-chevron-down-20-solid"
			:ui="{
				icon: {
					base: 'text-gray-400 dark:text-gray-500 ml-auto'
				}
			}"
			@click="isOpen = true"
			block
		>
			{{ localValue.label }}
		</UButton>
		<SearchFilterModal
			v-model="isOpen"
			:title="t('title')"
			:on-confirm="onConfirm"
			:on-clear="onClear"
		>
			<UCommandPalette
				v-model="localValue"
				:searchable="false"
				by="value"
				nullable
				:groups="[{ key: 'default', commands: options as [] }]"
				:ui="{
					wrapper: 'divide-none'
				}"
			>
				<template #default-inactive="{ command }">
					<UBadge
						v-if="command.disabled"
						color="orange"
						size="xs"
						class="opacity-50"
					>
						{{ t('message.soon') }}
					</UBadge>
				</template>
			</UCommandPalette>
		</SearchFilterModal>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"title": "Тип",
		"message": {
			"soon": "Скоро"
		}
	}
}
</i18n>