<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
	modelValue: {
		type: undefined as unknown as PropType<string | undefined>,
		required: true
	},
	placeholder: {
		type: String,
		required: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const { t } = useI18n({
	useScope: 'local'
})

const filterStore = useFilterStore()

const loading = ref(false)
const selected = ref(localValue.value)

const search = async (q: string) => {
	if (q?.length < 2 || !filterStore.onSearch) {
		return []
	}
	
	loading.value = true
	
	const result = await filterStore.onSearch({
		...filterStore.state,
		...{ search: q }
	})
	
	loading.value = false
	
	return result?.data ?? []
}

const onSelectLocal = async (val: any) => {
	if (filterStore.onSelect) {
		await filterStore.onSelect(val)
	}
}

const onBlur = () => {
	// query.value = ''
}
</script>

<template>
	<UInputMenu
		v-model="selected"
		:search="search"
		:loading="loading"
		:placeholder="placeholder"
		:nullable="true"
		class="flex-1"
		:ui-menu="{
			width: '!min-w-0',
			label: 'block truncate-none',
		}"
		trailing-icon="i-heroicons-magnifying-glass-20-solid"
		loading-icon="i-svg-spinners-ring-resize"
		option-attribute="title"
		by="id"
		trailing
		@change="onSelectLocal"
		@blur="onBlur"
	>
		<template #option="{ option }">
			{{ option.title }}
		</template>
		<template #option-empty="{ query }">
			<template v-if="loading">
				{{ t('loading') }}
			</template>
			<template v-else-if="query?.length < 3">
				{{ t('empty_query') }}
			</template>
			<template v-else>
        {{ t('not_found', { query }) }}
      </template>
		</template>
		<template #empty>
			{{ t('empty_query') }}
		</template>
	</UInputMenu>
</template>

<i18n lang="json">
{
	"ru": {
		"loading": "Идет поиск...",
		"empty_query": "Введите еще несколько символов",
		"not_found": "По запросу «{query}» ничего не найдено"
	}
}
</i18n>