import { createSharedComposable } from '@vueuse/core'
import lodash from 'lodash'

const { get, set } = lodash;

const _useArrayHelper = () => {
	const _get = (obj: any, path: any) => {
		return get(obj, path)
	}

	const _set = (obj: any, path: any, value: any) => {
		return set(obj, path, value)
	}

	return {
		get: _get,
		set: _set
	}
}

export const useArrayHelper = createSharedComposable(_useArrayHelper)