<script setup lang="ts">
const props = defineProps({
	modelValue: {
		type: Object,
		required: true
	},
	items: {
		type: Array as PropType<Array<any>>,
		required: true
	},
	title: {
		type: String,
		required: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const findItem = (item: any) => {
	return localValue.value.find((i: any) => i.id === item.id)
}

const hasItem = (item: any) => {
	return !!findItem(item)
}

const onSelect = (item: any) => {
	const found = findItem(item)
	if (found) {
		localValue.value.splice(localValue.value.indexOf(item), 1)
	}
	else {
		localValue.value.push(item)
	}
}
</script>

<template>
	<div
		v-bind="$attrs"
		class="flex flex-col md:flex-row gap-2 md:gap-6 items-start"
	>
		<div class="shrink-0 sm:max-w-32 md:max-w-44 lg:max-w-52 w-full md:text-right text-gray-600 text-sm md:text-base font-medium md:font-normal">
			{{ title }}
		</div>
		<div class="flex flex-wrap gap-2">
			<UBadge
				v-for="item in items"
				:key="item.id"
				:variant="hasItem(item) ? 'solid' : 'subtle'"
				class="cursor-pointer md:hover:bg-primary md:hover:text-white transition-colors duration-300"
				:ui="{
					base: 'text-xs md:text-sm px-2 md:px-2 py-1 md:py-1',
					font: 'font-normal',
					variant: {
						subtle: 'bg-slate-50',
					}
				}"
				@click="onSelect(item)"
			>
				{{ item.title }}
			</UBadge>
		</div>
	</div>
</template>