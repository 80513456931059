<script setup lang="ts">
import type { PropType } from 'vue'
import type { FilterInstalledItem } from '~/types'

const props = defineProps({
	items: {
		type: Object as PropType<FilterInstalledItem[]>,
		required: true
	},
	label: {
		type: String,
		required: true
	},
	onClear: {
		type: Function,
		required: true
	}
})

const items = computed(() => props.items.map((i: any) => i.label))
</script>

<template>
	<UBadge
		color="primary"
		variant="outline"
		class="flex items-center pr-1 font-normal"
	>
		<span>
			{{ label }}:
			{{ items.join(', ') }}
		</span>
		<UButton
			variant="link"
			color="primary"
			size="xs"
			icon="i-heroicons-x-mark"
			class="p-0.5"
			@click="onClear()"
		/>
	</UBadge>
</template>