<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const props = defineProps({
	modelValue: {
		type: Object,
		required: true,
	},
	autofocus: {
		type: Boolean,
		default: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const { t: tg } = useI18n({
	useScope: 'global'
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.isGreaterOrEqual('md')

const inputFocus = ref<any | null>(null)

const formHelper = useFormHelper()

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const onCheckFrom = (value: any) => {
	if (!formHelper.isNumber(value.from) || !formHelper.isNumber(value.to)) {
		return
	}
	if (value.from > value.to) {
		value.from = value.to
	}
}

const onCheckTo = (value: any) => {
	if (!formHelper.isNumber(value.from) || !formHelper.isNumber(value.to)) {
		return
	}
	if (value.to < value.from) {
		value.to = value.from
	}
}

onMounted(() => {
	if (props.autofocus) {
		setTimeout(() => {
			inputFocus.value?.input.focus()
		}, isDesktop ? 0 : 300)
	}
})
</script>

<template>
	<div
		v-bind="$attrs"
		class="flex gap-2 items-center"
	>
		<div class="flex flex-col gap-2 w-full md:w-auto">
			<div class="flex gap-2 items-center">
				<span class="truncate text-sm w-36 md:w-16">
					{{ tg('filter.field.house') }}
				</span>
				<UInput
					ref="inputFocus"
					v-model="localValue.house.from"
					type="number"
					:placeholder="tg('filter.placeholder.from')"
					class="w-full md:w-24"
					@blur="onCheckFrom(localValue.house)"
				>
					<template #trailing>
						<span class="text-sm">
							{{ tg('unit.square_meter') }}
						</span>
					</template>
				</UInput>
				<UInput
					v-model="localValue.house.to"
					type="number"
					:placeholder="tg('filter.placeholder.to')"
					class="w-full md:w-24"
					@blur="onCheckTo(localValue.house)"
				>
					<template #trailing>
						<span class="text-sm">
							{{ tg('unit.square_meter') }}
						</span>
					</template>
				</UInput>
			</div>
			<div class="flex gap-2 items-center">
				<span class="truncate text-sm w-36 md:w-16">
					{{ tg('filter.field.plot') }}
				</span>
				<UInput
					v-model="localValue.plot.from"
					type="number"
					:placeholder="tg('filter.placeholder.from')"
					class="w-full md:w-24"
					@blur="onCheckFrom(localValue.plot)"
				>
					<template #trailing>
						<span class="text-sm">
							{{ tg('unit.acres') }}
						</span>
					</template>
				</UInput>
				<UInput
					v-model="localValue.plot.to"
					type="number"
					:placeholder="tg('filter.placeholder.to')"
					class="w-full md:w-24"
					@blur="onCheckTo(localValue.plot)"
				>
					<template #trailing>
						<span class="text-sm">
							{{ tg('unit.acres') }}
						</span>
					</template>
				</UInput>
			</div>
		</div>
	</div>
</template>