<script setup lang="ts">

const props = defineProps({
	modelValue: {
		type: Object,
		required: true
	},
	items: {
		type: Array,
		default: () => ([])
	},
	url: {
		type: String,
		default: undefined,
	},
	searchField: {
		type: String,
		default: 'filter[title]'
	},
	query: {
		type: Object,
		default: () => ({})
	},
	sort: {
		type: String,
		default: 'title'
	},
	placeholder: {
		type: String,
		required: true
	},
	searchablePlaceholder: {
		type: String,
		default: undefined
	},
	multiple: {
		type: Boolean,
		default: true
	},
	loading: {
		type: Boolean,
		default: false
	},
	onChange: {
		type: Function,
		required: true
	},
})

const emits = defineEmits([
	'update:modelValue'
])

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const copiedValue = ref([ ...<[]>localValue.value ])

const isEmpty = computed(() => {
	return props.multiple
		? localValue.value.length === 0
		: Object.keys(props.modelValue).length === 0
})

const isLoading = ref(false)
const isOpen = ref(false)
const isConfirm = ref(false)

const onSearch = async (query: string) => {
	if (!props.url) {
		return []
	}
	
	isLoading.value = true
	
	const params = {...props.query, ...{
		[props.searchField]: query,
		sort: props.sort
	}} as any
	
	const result = await useAPIFetch<{ data: any }>(props.url, {
		query: params
	})
	
	isLoading.value = false
	
	return result.data
}

const onConfirm = () => {
	isConfirm.value = true
	localValue.value = [ ...<[]>copiedValue.value ]
	
	isOpen.value = false
}

const onDefault = () => {
	copiedValue.value = [ ...<[]>localValue.value ]
}

watch(localValue, (newVal: any) => {
	copiedValue.value = newVal
})

watch(isOpen, (newVal: any) => {
	if (!newVal) {
		if (!isConfirm.value) {
			onDefault()
		}
	}
	isConfirm.value = false
})

watch(copiedValue, async (newVal: any) => {
	props.onChange(newVal)
})

</script>

<template>
  <UiSelectMenu
    v-model="copiedValue"
    v-model:is-open="isOpen"
    :options="items"
    :searchable="url ? onSearch : false"
    :searchable-placeholder="searchablePlaceholder"
    :placeholder="placeholder"
    :multiple="multiple"
    :popper="{ placement: 'bottom-start' }"
    :ui="{
			wrapper: 'inherit md:relative'
    }"
    :ui-menu="{
			trigger: 'w-max md:w-full',
			width: 'w-fit',
			option: {
				base: 'gap-2 pr-9'
			}
    }"
    v-bind="$attrs"
    class="select-menu w-fit"
    option-attribute="title"
    by="id"
  >
    <template #label>
      <div
	      v-if="!isEmpty"
	      class="flex gap-1"
      >
	      <span class="flex max-w-80 truncate">
		      <template v-if="multiple">
			      <span>{{ localValue.map((v: any) => v.title)[0] }}</span>
			      <template v-if="localValue.length > 1">
				      <span class="w-[5ch] truncate">
					      , {{ localValue.map((v: any) => v.title)[1] }}
				      </span>
			      </template>
		      </template>
		      <template v-else>
			       {{ localValue.title }}
		      </template>
	      </span>
	      <UBadge
	        size="xs"
	        color="gray"
	      >
		      {{ multiple ? localValue.length : 1 }}
	      </UBadge>
      </div>
      <span v-else>
	      {{ placeholder }}
      </span>
    </template>
	  <template #button>
		  <UDivider class="pt-0" />
		  <div class="mt-1 ml-1 mr-1">
			  <UButton
				  size="xs"
				  :disabled="loading"
				  @click="onConfirm"
				  :ui="{
						padding: {
							xs: 'px-1'
						}
				  }"
				  block
			  >
				  <SearchFilterConfirm />
			  </UButton>
		  </div>
	  </template>
  </UiSelectMenu>
</template>