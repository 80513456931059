<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints, watchDebounced } from '@vueuse/core'
import { ModuleType, TagType } from '~/enums'

const props = defineProps({
	modelValue: {
		type: Object,
		required: true
	},
	isOpen: {
		type: Boolean,
		default: false
	}
})

const emits = defineEmits([
	'update:modelValue',
	'update:isOpen'
])

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const localIsOpen = computed({
	get: () => props.isOpen,
	set: (value) => emits('update:isOpen', value)
})

const { t: tg } = useI18n({
	useScope: 'global'
})

const { t } = useI18n({
	useScope: 'local'
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.isGreaterOrEqual('md')

const filterStore = useFilterStore()
const tagStore = useTagStore()

const purposeLands = tagStore.findByType(TagType.PurposeLand)
const communications = tagStore.findByType(TagType.Communications)
const heating = tagStore.findByType(TagType.Heating)
const safety = tagStore.findByType(TagType.Safety)
const infrastructures = tagStore.findByType(TagType.Infrastructure)
const villageNears = tagStore.findByType(TagType.VillageNear)
const roads = tagStore.findByType(TagType.Road)
const transports = tagStore.findByType(TagType.Transport)
const materials = tagStore.findByType(TagType.HouseMaterial)
const interiorDecorations = tagStore.findByType(TagType.InteriorDecoration)
const furniture = tagStore.findByType(TagType.Furniture)

const copiedValue = reactive({
	purpose_lands : [],
	communications : [],
	heating: [],
	safety : [],
	infrastructures : [],
	village_nears : [],
	roads : [],
	transports : [],
	materials: [],
	interior_decorations: [],
	furniture: []
} as any)

const hasValues = computed(() => {
	return copiedValue.purpose_lands.length > 0
		|| copiedValue.communications.length > 0
		|| copiedValue.heating.length > 0
		|| copiedValue.safety.length > 0
		|| copiedValue.infrastructures.length > 0
		|| copiedValue.village_nears.length > 0
		|| copiedValue.roads.length > 0
		|| copiedValue.transports.length > 0
		|| copiedValue.materials.length > 0
		|| copiedValue.interior_decorations.length > 0
		|| copiedValue.furniture.length > 0
})

const onDefault = () => {
	for (const key in copiedValue) {
		copiedValue[key] = [...localValue.value[key] ]
	}
}

const onReset = () => {
	for (const key in copiedValue) {
		copiedValue[key as keyof typeof copiedValue] = []
	}
}

const onClose = () => {
	localIsOpen.value = false
}

const onConfirm = () => {
	for (const key in copiedValue) {
		localValue.value[key] = [... copiedValue[key as keyof typeof copiedValue] ]
	}
	onClose()
}

const onClear = () => {
	onReset()
	onConfirm()
}

watch(localIsOpen, (newVal) => {
	newVal ? onDefault() : onReset()
})

watchDebounced(copiedValue, async (newVal: any) => {
	if (filterStore.onCount) {
		await filterStore.onCount({ ...localValue.value, ...newVal })
	}
}, {
	debounce: 300,
	maxWait: 1000
})
</script>

<template>
	<UModal
		v-model="localIsOpen"
		:fullscreen="!isDesktop"
		:ui="{
			width: 'w-full sm:max-w-2xl md:max-w-3xl lg:max-w-5xl',
		}"
	>
		<div class="flex flex-col gap-6 h-full">
			<div class="flex w-full pt-6 pl-6 pr-4">
				<div class="w-11"></div>
				<h3 class="flex-1 text-gray-600 text-2xl font-normal text-center">
					{{ t('title') }}
				</h3>
				<UButton
					variant="ghost"
					color="white"
					size="md"
					icon="i-heroicons-x-mark-16-solid"
					class="w-11 -mt-2 focus:ring-0"
					:ui="{
						icon: {
							base: '!w-7 !h-7 text-gray-500 hover:opacity-75 transition-opacity',
						}
					}"
					@click="onClose"
				/>
			</div>
			<div class="flex flex-col gap-4 px-6 flex-1 md:max-h-[540px] overflow-y-auto">
				<SearchFilterMoreItem
					v-model="copiedValue.purpose_lands"
					:title="tg('filter.field.purpose_lands')"
					:items="purposeLands"
				/>
				<SearchFilterMoreItem
					v-model="copiedValue.communications"
					:title="tg('filter.field.communications')"
					:items="communications"
				/>
				<SearchFilterMoreItem
					v-model="copiedValue.heating"
					:title="tg('filter.field.heating')"
					:items="heating"
				/>
				<SearchFilterMoreItem
					v-if="filterStore.moduleType === ModuleType.Village"
					v-model="copiedValue.safety"
					:title="tg('filter.field.safety')"
					:items="safety"
				/>
				<SearchFilterMoreItem
					v-model="copiedValue.infrastructures"
					:title="tg('filter.field.infrastructures')"
					:items="infrastructures"
				/>
				<SearchFilterMoreItem
					v-if="filterStore.moduleType === ModuleType.Village"
					v-model="copiedValue.village_nears"
					:title="tg('filter.field.village_nears')"
					:items="villageNears"
				/>
				<SearchFilterMoreItem
					v-if="filterStore.moduleType === ModuleType.Village"
					v-model="copiedValue.roads"
					:title="tg('filter.field.roads')"
					:items="roads"
				/>
				<SearchFilterMoreItem
					v-if="filterStore.moduleType === ModuleType.Village"
					v-model="copiedValue.transports"
					:title="tg('filter.field.transports')"
					:items="transports"
				/>
				<SearchFilterMoreItem
					v-if="filterStore.moduleType !== ModuleType.Village"
					v-model="copiedValue.materials"
					:title="tg('filter.field.materials')"
					:items="materials"
				/>
				<SearchFilterMoreItem
					v-if="filterStore.moduleType !== ModuleType.Village"
					v-model="copiedValue.interior_decorations"
					:title="tg('filter.field.interior_decorations')"
					:items="interiorDecorations"
				/>
				<SearchFilterMoreItem
					v-if="filterStore.moduleType !== ModuleType.Village"
					v-model="copiedValue.furniture"
					:title="tg('filter.field.furniture')"
					:items="furniture"
				/>
			</div>
			<div class="flex flex-col md:flex-row justify-center items-center px-6 pb-6 gap-1">
				<div class="w-48"></div>
				<div class="flex-1 flex justify-center">
					<UButton
						:disabled="filterStore.getPending ? filterStore.getPending() : false"
						@click="onConfirm"
					>
						<SearchFilterConfirm />
					</UButton>
				</div>
				<div class="flex w-48 justify-end">
					<UButton
						variant="link"
						leading-icon="i-heroicons-x-mark-16-solid"
						:disabled="!hasValues"
						@click="onClear"
					>
						{{ t('button.clear') }}
					</UButton>
				</div>
			</div>
		</div>
	</UModal>
</template>

<i18n lang="json">
{
	"ru": {
		"title": "Еще фильтры",
		"button": {
			"clear": "Сбросить"
		}
	}
}
</i18n>