<script setup lang="ts">
import type { FilterInstalled } from '~/types'

const props = defineProps({
	modelValue: {
		type: Object,
		required: true
	},
	onClear: {
		type: Function,
		required: true
	}
})

const { t: tg } = useI18n({
	useScope: 'global'
})

const { t } = useI18n({
	useScope: 'local'
})

const emits = defineEmits([
	'update:modelValue'
])

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const types = computed(() => {
	return localValue.value.types.map((item: any) => {
		return {
			label: item.title,
			callback: () => {
				localValue.value.types.splice(localValue.value.types.indexOf(item), 1)
			}
		}
	})
})

const classes = computed(() => {
	return localValue.value.class.map((item: any) => {
		return {
			label: item.title,
			callback: () => {
				localValue.value.class.splice(localValue.value.class.indexOf(item), 1)
			}
		}
	})
})

const highways = computed(() => {
	return localValue.value.highway.map((item: any) => {
		return {
			label: item.title,
			callback: () => {
				localValue.value.highway.splice(localValue.value.highway.indexOf(item), 1)
			}
		}
	})
})

const mkad = computed(() => {
	const tmp = []
	if (localValue.value.mkad.from) {
		tmp.push(tg('filter.placeholder.from') + ' ' + localValue.value.mkad.from)
	}
	if (localValue.value.mkad.to) {
		tmp.push(tg('filter.placeholder.to') + ' ' + localValue.value.mkad.to)
	}
	return tmp.length ? [{
		label: tmp.join(' ') + ' ' + tg('unit.km'),
		callback: () => {
			props.onClear('mkad')
		}
	}] : []
})

const price = computed(() => {
	const tmp = []
	if (localValue.value.price.from) {
		tmp.push(tg('filter.placeholder.from') + ' ' + localValue.value.price.from)
	}
	if (localValue.value.price.to) {
		tmp.push(tg('filter.placeholder.from') + ' ' + localValue.value.price.to)
	}
	return tmp.length ? [{
		label: tmp.join(' ') + ' ' + tg('unit.currency.rub'),
		callback: () => {
			props.onClear('price')
		}
	}] : []
})

const area = computed(() => {
	const tmp = []
	
	const tmpHouse = []
	if (localValue.value.area.house.from) {
		tmpHouse.push(tg('filter.placeholder.from') + ' ' + localValue.value.area.house.from)
	}
	if (localValue.value.area.house.to) {
		tmpHouse.push(tg('filter.placeholder.from') + ' ' + localValue.value.area.house.to)
	}
	if (tmpHouse.length) {
		tmp.push({
			label: tg('filter.field.house') + ' ' + tmpHouse.join(' ') + tg('unit.square_meter'),
			callback: () => {
				props.onClear('area.house')
			}
		})
	}
	
	const tmpPlot = []
	if (localValue.value.area.plot.from) {
		tmpPlot.push(tg('filter.placeholder.from') + ' ' + localValue.value.area.plot.from)
	}
	if (localValue.value.area.plot.to) {
		tmpPlot.push(tg('filter.placeholder.from') + ' ' + localValue.value.area.plot.to)
	}
	if (tmpPlot.length) {
		tmp.push({
			label: tg('filter.field.plot') + ' ' + tmpPlot.join(' ') + ' ' + tg('unit.acres'),
			callback: () => {
				props.onClear('area.plot')
			}
		})
	}
	
	return tmp
})

const purposeLands = computed(() => {
	return getField('purpose_lands')
})

const communications = computed(() => {
	return getField('communications')
})

const heating = computed(() => {
	return getField('heating')
})

const safety = computed(() => {
	return getField('safety')
})

const infrastructures = computed(() => {
	return getField('infrastructures')
})

const villageNears = computed(() => {
	return getField('village_nears')
})

const roads = computed(() => {
	return getField('roads')
})

const transports = computed(() => {
	return getField('transports')
})

const materials = computed(() => {
	return getField('materials')
})

const interiorDecorations = computed(() => {
	return getField('interior_decorations')
})

const furniture = computed(() => {
	return getField('furniture')
})

const items = computed(() => [
	{ field: 'types', label: tg('filter.field.type'), value: types.value } as FilterInstalled,
	{ field: 'area', label: tg('filter.field.area'), value: area.value } as FilterInstalled,
	{ field: 'class', label: tg('filter.field.class'), value: classes.value } as FilterInstalled,
	{ field: 'highway', label: tg('filter.field.highway'), value: highways.value } as FilterInstalled,
	{ field: 'mkad', label: tg('filter.field.mkad'), value: mkad.value } as FilterInstalled,
	{ field: 'price', label: tg('filter.field.price'), value: price.value } as FilterInstalled,
	{ field: 'purpose_lands', label: tg('filter.field.purpose_lands'), value: purposeLands.value } as FilterInstalled,
	{ field: 'communications', label: tg('filter.field.communications'), value: communications.value } as FilterInstalled,
	{ field: 'heating', label: tg('filter.field.heating'), value: heating.value } as FilterInstalled,
	{ field: 'safety', label: tg('filter.field.safety'), value: safety.value } as FilterInstalled,
	{ field: 'infrastructures', label: tg('filter.field.infrastructures'), value: infrastructures.value } as FilterInstalled,
	{ field: 'village_nears', label: tg('filter.field.village_nears'), value: villageNears.value } as FilterInstalled,
	{ field: 'roads', label: tg('filter.field.roads'), value: roads.value } as FilterInstalled,
	{ field: 'transports', label: tg('filter.field.transports'), value: transports.value } as FilterInstalled,
	{ field: 'materials', label: tg('filter.field.materials'), value: materials.value } as FilterInstalled,
	{ field: 'interior_decorations', label: tg('filter.field.interior_decorations'), value: interiorDecorations.value } as FilterInstalled,
	{ field: 'furniture', label: tg('filter.field.furniture'), value: furniture.value } as FilterInstalled
])

const getField = (field: any) => {
	return localValue.value[field].map((item: any) => {
		return {
			label: item.title,
			callback: () => {
				localValue.value[field].splice(localValue.value[field].indexOf(item), 1)
			}
		}
	})
}

</script>

<template>
	<div class="flex items-start gap-4 mt-3 md:mt-6 px-3 md:px-0">
		<div class="hidden md:block max-w-44 shrink-0 w-full text-gray-600 italic text-sm leading-loose">
			{{ t('filters_installed') }}
		</div>
		<div class="flex flex-1 flex-wrap gap-1 items-center">
			<template v-for="(item, i) in items" >
				<SearchFilterInstalledItem
					v-if="item.value.length > 0"
					:key="i"
					:label="item.label"
					:items="item.value"
					:on-clear="() => onClear(item.field)"
				/>
			</template>
		</div>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"filters_installed": "Установлены фильтры"
	}
}
</i18n>